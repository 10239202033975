var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: !0
});
var d = void 0,
    e = void 0,
    g = void 0,
    m = void 0,
    n = void 0;
exports.unstable_now = void 0;
exports.unstable_forceFrameRate = void 0;

if ("undefined" === typeof window || "function" !== typeof MessageChannel) {
  var p = null,
      q = null,
      r = function () {
    if (null !== p) try {
      var a = exports.unstable_now();
      p(!0, a);
      p = null;
    } catch (b) {
      throw setTimeout(r, 0), b;
    }
  };

  exports.unstable_now = function () {
    return Date.now();
  };

  d = function (a) {
    null !== p ? setTimeout(d, 0, a) : (p = a, setTimeout(r, 0));
  };

  e = function (a, b) {
    q = setTimeout(a, b);
  };

  g = function () {
    clearTimeout(q);
  };

  m = function () {
    return !1;
  };

  n = exports.unstable_forceFrameRate = function () {};
} else {
  var t = window.performance,
      u = window.Date,
      v = window.setTimeout,
      w = window.clearTimeout,
      x = window.requestAnimationFrame,
      y = window.cancelAnimationFrame;
  "undefined" !== typeof console && ("function" !== typeof x && console.error("This browser doesn't support requestAnimationFrame. Make sure that you load a polyfill in older browsers. https://fb.me/react-polyfills"), "function" !== typeof y && console.error("This browser doesn't support cancelAnimationFrame. Make sure that you load a polyfill in older browsers. https://fb.me/react-polyfills"));
  exports.unstable_now = "object" === typeof t && "function" === typeof t.now ? function () {
    return t.now();
  } : function () {
    return u.now();
  };
  var z = !1,
      A = null,
      B = -1,
      C = -1,
      D = 33.33,
      E = -1,
      F = -1,
      G = 0,
      H = !1;

  m = function () {
    return exports.unstable_now() >= G;
  };

  n = function () {};

  exports.unstable_forceFrameRate = function (a) {
    0 > a || 125 < a ? console.error("forceFrameRate takes a positive int between 0 and 125, forcing framerates higher than 125 fps is not unsupported") : 0 < a ? (D = Math.floor(1000 / a), H = !0) : (D = 33.33, H = !1);
  };

  var J = function () {
    if (null !== A) {
      var a = exports.unstable_now(),
          b = 0 < G - a;

      try {
        A(b, a) || (A = null);
      } catch (c) {
        throw I.postMessage(null), c;
      }
    }
  },
      K = new MessageChannel(),
      I = K.port2;

  K.port1.onmessage = J;

  var L = function (a) {
    if (null === A) F = E = -1, z = !1;else {
      z = !0;
      x(function (a) {
        w(B);
        L(a);
      });

      var b = function () {
        G = exports.unstable_now() + D / 2;
        J();
        B = v(b, 3 * D);
      };

      B = v(b, 3 * D);

      if (-1 !== E && 0.1 < a - E) {
        var c = a - E;
        !H && -1 !== F && c < D && F < D && (D = c < F ? F : c, 8.33 > D && (D = 8.33));
        F = c;
      }

      E = a;
      G = a + D;
      I.postMessage(null);
    }
  };

  d = function (a) {
    A = a;
    z || (z = !0, x(function (a) {
      L(a);
    }));
  };

  e = function (a, b) {
    C = v(function () {
      a(exports.unstable_now());
    }, b);
  };

  g = function () {
    w(C);
    C = -1;
  };
}

var M = null,
    N = null,
    O = null,
    P = 3,
    Q = !1,
    R = !1,
    S = !1;

function T(a, b) {
  var c = a.next;
  if (c === a) M = null;else {
    a === M && (M = c);
    var f = a.previous;
    f.next = c;
    c.previous = f;
  }
  a.next = a.previous = null;
  c = a.callback;
  f = P;
  var l = O;
  P = a.priorityLevel;
  O = a;

  try {
    var h = a.expirationTime <= b;

    switch (P) {
      case 1:
        var k = c(h);
        break;

      case 2:
        k = c(h);
        break;

      case 3:
        k = c(h);
        break;

      case 4:
        k = c(h);
        break;

      case 5:
        k = c(h);
    }
  } catch (Z) {
    throw Z;
  } finally {
    P = f, O = l;
  }

  if ("function" === typeof k) if (b = a.expirationTime, a.callback = k, null === M) M = a.next = a.previous = a;else {
    k = null;
    h = M;

    do {
      if (b <= h.expirationTime) {
        k = h;
        break;
      }

      h = h.next;
    } while (h !== M);

    null === k ? k = M : k === M && (M = a);
    b = k.previous;
    b.next = k.previous = a;
    a.next = k;
    a.previous = b;
  }
}

function U(a) {
  if (null !== N && N.startTime <= a) {
    do {
      var b = N,
          c = b.next;
      if (b === c) N = null;else {
        N = c;
        var f = b.previous;
        f.next = c;
        c.previous = f;
      }
      b.next = b.previous = null;
      V(b, b.expirationTime);
    } while (null !== N && N.startTime <= a);
  }
}

function W(a) {
  S = !1;
  U(a);
  R || (null !== M ? (R = !0, d(X)) : null !== N && e(W, N.startTime - a));
}

function X(a, b) {
  R = !1;
  S && (S = !1, g());
  U(b);
  Q = !0;

  try {
    if (!a) for (; null !== M && M.expirationTime <= b;) T(M, b), b = exports.unstable_now(), U(b);else if (null !== M) {
      do T(M, b), b = exports.unstable_now(), U(b); while (null !== M && !m());
    }
    if (null !== M) return !0;
    null !== N && e(W, N.startTime - b);
    return !1;
  } finally {
    Q = !1;
  }
}

function Y(a) {
  switch (a) {
    case 1:
      return -1;

    case 2:
      return 250;

    case 5:
      return 1073741823;

    case 4:
      return 10000;

    default:
      return 5000;
  }
}

function V(a, b) {
  if (null === M) M = a.next = a.previous = a;else {
    var c = null,
        f = M;

    do {
      if (b < f.expirationTime) {
        c = f;
        break;
      }

      f = f.next;
    } while (f !== M);

    null === c ? c = M : c === M && (M = a);
    b = c.previous;
    b.next = c.previous = a;
    a.next = c;
    a.previous = b;
  }
}

var aa = n;
exports.unstable_ImmediatePriority = 1;
exports.unstable_UserBlockingPriority = 2;
exports.unstable_NormalPriority = 3;
exports.unstable_IdlePriority = 5;
exports.unstable_LowPriority = 4;

exports.unstable_runWithPriority = function (a, b) {
  switch (a) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      break;

    default:
      a = 3;
  }

  var c = P;
  P = a;

  try {
    return b();
  } finally {
    P = c;
  }
};

exports.unstable_next = function (a) {
  switch (P) {
    case 1:
    case 2:
    case 3:
      var b = 3;
      break;

    default:
      b = P;
  }

  var c = P;
  P = b;

  try {
    return a();
  } finally {
    P = c;
  }
};

exports.unstable_scheduleCallback = function (a, b, c) {
  var f = exports.unstable_now();

  if ("object" === typeof c && null !== c) {
    var l = c.delay;
    l = "number" === typeof l && 0 < l ? f + l : f;
    c = "number" === typeof c.timeout ? c.timeout : Y(a);
  } else c = Y(a), l = f;

  c = l + c;
  a = {
    callback: b,
    priorityLevel: a,
    startTime: l,
    expirationTime: c,
    next: null,
    previous: null
  };

  if (l > f) {
    c = l;
    if (null === N) N = a.next = a.previous = a;else {
      b = null;
      var h = N;

      do {
        if (c < h.startTime) {
          b = h;
          break;
        }

        h = h.next;
      } while (h !== N);

      null === b ? b = N : b === N && (N = a);
      c = b.previous;
      c.next = b.previous = a;
      a.next = b;
      a.previous = c;
    }
    null === M && N === a && (S ? g() : S = !0, e(W, l - f));
  } else V(a, c), R || Q || (R = !0, d(X));

  return a;
};

exports.unstable_cancelCallback = function (a) {
  var b = a.next;

  if (null !== b) {
    if (a === b) a === M ? M = null : a === N && (N = null);else {
      a === M ? M = b : a === N && (N = b);
      var c = a.previous;
      c.next = b;
      b.previous = c;
    }
    a.next = a.previous = null;
  }
};

exports.unstable_wrapCallback = function (a) {
  var b = P;
  return function () {
    var c = P;
    P = b;

    try {
      return a.apply(this, arguments);
    } finally {
      P = c;
    }
  };
};

exports.unstable_getCurrentPriorityLevel = function () {
  return P;
};

exports.unstable_shouldYield = function () {
  var a = exports.unstable_now();
  U(a);
  return null !== O && null !== M && M.startTime <= a && M.expirationTime < O.expirationTime || m();
};

exports.unstable_requestPaint = aa;

exports.unstable_continueExecution = function () {
  R || Q || (R = !0, d(X));
};

exports.unstable_pauseExecution = function () {};

exports.unstable_getFirstCallbackNode = function () {
  return M;
};

export default exports;